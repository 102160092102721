import { faSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "@material-ui/core/Popover";
import classnames from "classnames";
import React, { useState, useEffect } from "react";
import { ChromePicker, ColorResult } from "react-color";

import "./ColorPicker.scss";

type ColorString = string;

interface ColorPickerProps {
  className?: string;
  color: ColorString | null;
  onChange: (color: ColorString) => void;
  onClose?: (color: ColorString) => void;
}
export const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  const [isPickerDisplayed, setPickerDisplayed] = useState(false);
  const [color, setColor] = useState(props.color);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const onChange = (event: ColorResult) => setColor(event.hex);
  const onChangeComplete = (event: ColorResult) => props.onChange(event.hex);

  useEffect(() => {
    setColor(props.color);
  }, [props.color]);

  return (
    <div className={classnames("color-picker-container", props.className)}>
      <div
        className="color-pill"
        style={{
          // backgroundColor: color ?? undefined,
          background: color
            ? color
            : "linear-gradient(to top right, #fff calc(50% - 1px), #aaa, #fff calc(50% + 1px)",
        }}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setPickerDisplayed(true);
        }}
      >
        {/* {color ? null : (
                    <div className='color-pill-transparent'>
                    </div>
                )} */}
      </div>

      {isPickerDisplayed ? (
        <Popover
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={() => {
            setPickerDisplayed(false);
            if (color && props.onClose) {
              props.onClose(color);
            }
          }}
        >
          <ChromePicker
            disableAlpha
            color={color || "#ffffff"}
            className="color-picker"
            onChange={onChange}
            onChangeComplete={onChangeComplete}
          />
          <button
            onClick={() => setPickerDisplayed(false)}
            className="btn-close-color-picker"
          >
            OK
          </button>
        </Popover>
      ) : null}
    </div>
  );
};
