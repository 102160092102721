import {
  faImage,
  faArrowsAlt,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Product, ProductVariationStock } from "../../domain/Product";
import { ColorSelector } from "../Color/ColorSelector";
import { Imagedit } from "../common/Imageedit/Imagedit";
import { OrderableGrid } from "../common/OrderableGrid/OrderableGrid";
import { OrderableGridHandle } from "../common/OrderableGrid/OrderableGridHandle";
import { OrderableGridItem } from "../common/OrderableGrid/OrderableGridItem";
import { SizeSelector } from "../Size/SizeSelector";
import { TextureSelector } from "../Texture/TextureSelector";

import {
  selectProductSizes,
} from "./state/product.state";
import { stocksActions, StockState, selectStock } from "./state/stocks.state";
import {
  variationsActions,
  VariationState,
  selectVariations,
  makeNewVariation,
} from "./state/variations.state";

import "./ProductVariations.scss";
import { toBase64 } from "../../api/brand.api";
import { useTranslation } from "react-i18next";

const newStock = (): ProductVariationStock => ({
  id: -1,
  referenceCode: "",
  quantity: 0,
  sizeId: -1,
});

const ProductVariationStockRender: React.FC<{
  id: StockState["id"];
  index: number;
  helpers: FieldArrayRenderProps;
  variationId: VariationState["id"];
}> = (props) => {
  const { id, variationId } = props;
  const dispatch = useDispatch();
  const stock = useSelector(selectStock(props.id));
  const productSizes = useSelector(selectProductSizes);

  return (
    <div className="product-variation-stock">
      <SizeSelector
        include={productSizes.map((size) => size.id)}
        value={stock.sizeId}
        onChange={(size) =>
          dispatch(stocksActions.stockSizeChanged({ id, sizeId: size.id }))
        }
      />

      <input
        type="number"
        className="product-variation-stock-quantity-input"
        value={stock.quantity}
        onChange={(event) =>
          dispatch(
            stocksActions.stockQuantityChanged({
              id,
              quantity: parseInt(event.target.value, 10),
            })
          )
        }
      />
      <FontAwesomeIcon
        className="icon-button"
        icon={faTrash}
        onClick={() =>
          dispatch(stocksActions.stockRemoved({ variationId, id }))
        }
      />
    </div>
  );
};

const ProductVariation: React.FC<{
  id: number;
  index: number;
  helpers: FieldArrayRenderProps;
}> = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const allVariations = useSelector(selectVariations);
  const { values, setFieldValue } = useFormikContext<Product>();
  const [variation, setVariation] = useState(
    values.variations.find((v) => v.id === props.id)
  );
  const [imageSrc, setImageSrc] = useState("");
  const [images, setImages] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setVariation(values.variations.find((v) => v.id === props.id));
  }, [values.variations]);

  useEffect(() => {
    if (variation) {
      setImageSrc(
        variation.images.length > 0 ? variation.images[0].picture_600 : ""
      );
      setImages(
        variation.images.map((img: any) => ({
          ...img,
          id: img.id,
          src: img.src,
          originalImage: img.src,
          crop: {},
          remover: img.remover ? true : false,
        }))
      );
    }
  }, [variation]);

  if (!variation) {
    return null;
  }

  const handleOnChange = (newImages: any) => {
    let variations = [...values.variations.filter((v) => v.id !== props.id)];
    let variation: any = {
      ...values.variations.find((v) => v.id === props.id),
    };
    if (variation) {
      variation.images = newImages;
      variations.push(variation);
      setFieldValue(
        "variations",
        variations.sort((a: any, b: any) => (a.order > b.order ? 1 : -1))
      );
      setVariation(variation);
    }
  };

  const handleOnRemove = () => {
    if (!variation.id) {
      props.helpers.remove(props.index);
    }

    let variations = [...values.variations];
    let variationChange: any = variations.find((v) => v.id === variation.id);
    variationChange = { ...variationChange, remover: true };
    variations = variations.filter((v) => v.id !== variation.id);
    variations.push(variationChange);
    setFieldValue("variations", variations);
  };

  return (
    <div className="product-variation-wrapper">
      <div className="product-variation">
        <div className="">
          <ColorSelector
            value={variation.color?.id || 0}
            onChange={(newColor) => {
              const newVariations = [...values.variations];
              setFieldValue("variations", [
                ...newVariations.map((variationMap) => {
                  if (variationMap.id === variation.id) {
                    const newVariation = { ...variationMap };
                    newVariation.color = newColor;
                    return newVariation;
                  }
                  return variationMap;
                }),
              ]);
            }}
          />
          <TextureSelector
            value={variation.texture?.id || 0}
            onChange={(newTexture) => {
              const newVariations = [...values.variations];
              setFieldValue("variations", [
                ...newVariations.map((variationMap) => {
                  if (variationMap.id === variation.id) {
                    const newVariation = { ...variationMap };
                    newVariation.texture = newTexture;
                    return newVariation;
                  }
                  return variationMap;
                }),
              ]);
            }}
          />

          {/* <FieldArray
                        name='stock'
                        render={arrayHelpers => (
                            <div className='product-stocks'>
                                <div className='product-label'>Stocks</div>

                                {variation.stock.map((stock, index) => (
                                    <ProductVariationStockRender
                                        key={stock.id}
                                        id={stock.id}
                                        index={index}
                                        helpers={arrayHelpers}
                                        variationId={variation.id}
                                    />
                                ))}
                                <div
                                    className='icon-button'
                                    onClick={() => {
                                        console.log('adding new variation', newStock());

                                        arrayHelpers.push(newStock());
                                        // dispatch(stocksActions.stockAdded({ variationId: variation.id }))
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    <span>Add stock</span>
                                </div>
                            </div>
                        )}
                    /> */}
        </div>

        <Imagedit
          noCrop
          allowEmpty={true}
          multiple
          className="product-variation-image"
          desiredDimensions={{ width: 0, height: 0 }}
          helpDimensions={{ width: 600, height: 900 }}
          render={() =>
            imageSrc ? (
              <img src={imageSrc} />
            ) : (
              <div className="placeholder-image">
                <FontAwesomeIcon icon={faImage} />
              </div>
            )
          }
          onSave={async (images) => {
            const newImages: any = [];
            for (let index = 0; index < images.length; index++) {
              const image = images[index];
              newImages.push({
                id: image.id as number,
                src: image.src,
                picture_600: image.src,
                picture_thumbnail: image.src,
                nova_imagem: await toBase64(image.file),
                file: image.file,
                remover: image.remover,
                order: index,
              });
            }
            handleOnChange(newImages);
            dispatch(
              variationsActions.changeImages({
                id: variation.id,
                images: newImages,
              })
            );
          }}
          title={t("Variation image", "Variation image")}
          src={images}
        />
      </div>
      <div className="product-variation-actions">
        <OrderableGridHandle>
          <FontAwesomeIcon icon={faArrowsAlt} />
        </OrderableGridHandle>

        <FontAwesomeIcon
          className="icon-button"
          icon={faTrash}
          onClick={handleOnRemove}
        />
      </div>
    </div>
  );
};

const newVariation = () => ({
  // id:
  stock: [],
  images: [],
  order: 0,
  color: null,
  textureId: null,
});

export const ProductVariations: React.FC = () => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext<Product>();
  const { t } = useTranslation();

  const moveItem = (sourceId: any, destinationId: any) => {
    setFieldValue(
      "variations",
      immutableMove(
        values.variations,
        values.variations.findIndex((variation) => variation.id === sourceId),
        values.variations.findIndex(
          (variation) => variation.id === destinationId
        )
      )
    );
  };

  function immutableMove(arr: any, from: any, to: any) {
    return arr.reduce((prev: any, current: any, idx: any, self: any) => {
      if (from === to) {
        prev.push(current);
      }
      if (idx === from) {
        return prev;
      }
      if (from < to) {
        prev.push(current);
      }
      if (idx === to) {
        prev.push(self[from]);
      }
      if (from > to) {
        prev.push(current);
      }
      return prev;
    }, []);
  }

  const handleAddVariation = () => {
    const newVariation = makeNewVariation();
    newVariation.order = values.variations.length;
    setFieldValue("variations", [...values.variations, newVariation]);
  };

  return (
    <FieldArray
      name="variations"
      render={(arrayHelpers) => (
        <div className="product-variations">
          <div className="subtitle">{t("Variations", "Variations")}</div>
          <OrderableGrid itemsType="variations">
            {values.variations
              .filter((v) => !v.remover)
              .map((variation, index) => (
                <OrderableGridItem
                  key={variation.id}
                  id={variation.id}
                  onMoveItem={moveItem}
                >
                  <ProductVariation
                    key={variation.id}
                    id={variation.id}
                    index={index}
                    helpers={arrayHelpers}
                  />
                </OrderableGridItem>
              ))}
          </OrderableGrid>
          <div className="icon-button" onClick={() => handleAddVariation()}>
            <FontAwesomeIcon icon={faPlus} />
            <span>{t("Add variation", "Add variation")}</span>
          </div>
        </div>
      )}
    />
  );
};
