import {
  faArrowsAlt,
  faPlus,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { IconButton } from "../../common/buttons/IconButton";
import { Section } from "../../common/Section";
import { HintText } from "../../common/typography/HintText";
import { useCollectionState } from "../../common/useCollectionState";
import { CollectionProduct } from "../../domain/Collection";
import { ListCard } from "../../external/common/ListCard";
import { OrderableGrid } from "../../external/common/OrderableGrid/OrderableGrid";
import { OrderableGridHandle } from "../../external/common/OrderableGrid/OrderableGridHandle";
import { OrderableGridItem } from "../../external/common/OrderableGrid/OrderableGridItem";
import { SmartModal } from "../../external/common/SmartModal";
import { AddProduct } from "./AddProduct";
import styles from "./CollectionProducts.module.scss";
import { API_URL } from "../../config";

export const CollectionProducts: React.FC<{
  products?: CollectionProduct[];
  onChange: (products: CollectionProduct[]) => void;
}> = (props) => {
  const {
    add,
    move,
    items: products,
    isChanged,
  } = useCollectionState(props.products || []);
  const location = useLocation();

  const apiToken = localStorage.getItem('api_token');
  const onAdd = add;
  const onMove = move;

  useEffect(() => {
    if (isChanged) {
      props.onChange(products);
    }
  }, [products, isChanged]);
  const { t } = useTranslation();

  return (
    <Section
      title={t("Product list", "Product list")}
      className={styles.collectionProducts}
    >
      {products.length > 0 ? (
        <div className={styles.collectionProductList}>
          <OrderableGrid
            itemsType="collection-product"
            className={styles.collectionProductList}
          >
            {products.map((product) => (
              <OrderableGridItem
                key={product.id}
                id={product.id}
                onMoveItem={onMove}
              >
                <ListCard
                  key={product.id}
                  title={product.name}
                  className={styles.listCard}
                  image={product.image || ""}
                >
                  <div className={styles.editProductAction}>
                    <a href={`${API_URL}/a-dmin/products/${product.id}/edit?token=${apiToken}`} target="_blank">
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </a>
                  </div>
                  <div className={styles.actions}>
                    <OrderableGridHandle>
                      <FontAwesomeIcon icon={faArrowsAlt} />
                    </OrderableGridHandle>
                  </div>
                </ListCard>
              </OrderableGridItem>
            ))}
          </OrderableGrid>
        </div>
      ) : (
        <HintText>{t("List is empty", "List is empty")}</HintText>
      )}

      {location.pathname !== "/create-collection" ? (
        <SmartModal
          body={({ closeModal }) => (
            <AddProduct
              disabledProductIds={products.map((p) => p.id)}
              onSave={(product) => {
                onAdd(product);
                closeModal();
              }}
            />
          )}
        >
          <IconButton icon={faPlus} className={styles.addButton}>
            {t("Select a product to the collection", "Select a product to the collection")}
          </IconButton>
        </SmartModal>
      ) : (
        <></>
      )}
    </Section>
  );
};
