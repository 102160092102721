import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router";
import "../../src/common/ReactPaginate.scss";
import { useApi } from "../api/useApi";
import { noop } from "../external/common/helpers";
import { ProductList } from "../external/Products/ProductList";
import { useAsync } from "../external/useAsync";
import { useRoutes } from "../useRoutes";
import { Page } from "./common/Page";
import { PageError } from "./common/PageError";
import { PageLoadingSpinner } from "./common/PageLoadingSpinner";
import registerAccessGtm from "../services/gtmRegister";

export const ProductListPage: React.FC = () => {
  const { user, fetchProductList } = useApi();
  const history = useHistory();
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(
    Number(new URLSearchParams(history.location.search).get("page")) || 1
  );

  const { isPending, isSuccess, isFailure, start, results } = useAsync(
    fetchProductList(page)
  );
  const routes = useRoutes();

  const onAddProduct = () => {
    routes.products.navigateToCreate();
  };

  useEffect(() => {
    registerAccessGtm()
  }, [])

  useEffect(() => {
    start(page).catch(noop);
  }, [page]);

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  if (isFailure) {
    return <PageError message={t("Error fetching data", "Error fetching data")} />;
  }

  if (isSuccess && !!results) {
    return (
      <Page className="product-management">
        <div className="page-title">
          {t("Products management", "Products management")}
          <span className="add-button icon-button" onClick={onAddProduct}>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </div>

        <ProductList products={results.data} />
        <ReactPaginate
          containerClassName="react-paginate"
          pageCount={results.meta.last_page}
          pageRangeDisplayed={results.meta.per_page}
          marginPagesDisplayed={5}
          previousLabel="<<"
          nextLabel=">>"
          activeClassName="active"
          onPageChange={(page) => {
            history.push(`?page=${page.selected + 1}`);
            setPage(page.selected + 1);
          }}
        />
      </Page>
    );
  }

  return null;
};
