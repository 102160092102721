import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, MenuItem } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { ProductVariationColor } from "../../domain/Product";
import { SmartModal } from "../common/SmartModal";

import { AddNewColor } from "./AddColor";
import { selectColors, colorsActions } from "./colors.state";

import "./ColorSelector.scss";

export const ColorSelector: React.FC<{
  value: ProductVariationColor["id"] | null;
  onChange: (color: ProductVariationColor | null) => void;
}> = (props) => {
  const { value, onChange } = props;

  const colors = useSelector(selectColors);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="color-selector">
      <Select
        className="product-color-select"
        value={value}
        label={t("Color", "Color")}
        placeholder={t("Select color", "Select color")}
        onChange={(event) => {
          const selectedColor = event.target.value as string;
          const newColor = selectedColor
            ? colors.find((color) => color.id === event.target.value)
            : null;
          onChange(newColor as ProductVariationColor | null);
        }}
      >
        <MenuItem value={0}>{t("Select color", "Select color")}</MenuItem>
        {colors.map((color) => (
          <MenuItem key={color.id} value={color.id}>
            {color.name}{" "}
            <div
              style={{
                display: "inline-block",
                backgroundColor: color.color,
                width: "16px",
                height: "16px",
                marginLeft: "16px",
                position: "relative",
              }}
            >
              {color.extraColor !== "" && (
                <span
                  style={{
                    display: "inline-block",
                    position: "absolute",
                    top: 0,
                    borderLeft: "16px solid transparent",
                    borderBottom: `16px solid ${color.extraColor}`,
                  }}
                />
              )}
            </div>
          </MenuItem>
        ))}
      </Select>
      <SmartModal
        body={({ closeModal }) => (
          <AddNewColor
            onSave={(args) => {
              let newColor = colorsActions.added(args);
              dispatch(newColor);
              onChange(newColor.payload as ProductVariationColor | null);
              closeModal();
            }}
          />
        )}
      >
        <FontAwesomeIcon icon={faPlus} className="icon-button" />
      </SmartModal>
    </div>
  );
};
