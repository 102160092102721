import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useApi } from "../api/useApi";
import { BrandProfile } from "../domain/BrandProfile";
import { ColorList } from "../external/Color/ColorList";
import { colorsActions } from "../external/Color/colors.state";
import { noop } from "../external/common/helpers";
import { TextureList } from "../external/Texture/TextureList";
import { texturesActions } from "../external/Texture/textures.state";
import { useAsync } from "../external/useAsync";

import { Page } from "./common/Page";
import { PageError } from "./common/PageError";
import { PageLoadingSpinner } from "./common/PageLoadingSpinner";
import registerAccessGtm from "../services/gtmRegister";

export const ColorAndTextureListPage: React.FC = () => {
  const dispatch = useDispatch();
  const { user, fetchColors, fetchTextures } = useApi();
  const { t } = useTranslation();

  const { isPending, isSuccess, isFailure, start } = useAsync(
    (brandId: BrandProfile["id"]) =>
      Promise.all([fetchColors(brandId), fetchTextures(brandId)])
  );
  useEffect(() => {
    start(user.brandId)
      .then(([colors, textures]) => {
        dispatch(colorsActions.fetched(colors));
        dispatch(texturesActions.fetched(textures));
      })
      .catch(noop);
  }, []);

  useEffect(() => {
    registerAccessGtm()
  }, [])

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  if (isFailure) {
    return <PageError message={t("Error fetching data", "Error fetching data")} />;
  }

  if (isSuccess) {
    return (
      <Page className="color-and-texture-management">
        <div className="page-title">
          {t("Color management", "Color management")}
        </div>
        <ColorList />
        <div style={{ height: "50px" }}></div>
        <div className="page-title">
          {t("Texture management", "Texture management")}
        </div>
        <TextureList />
      </Page>
    );
  }

  return null;
};
