import {
  faEllipsisH,
  faTrash,
  faMinus,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListItemIcon,
  ListItemText,
  Card,
  CardActions,
  CardContent,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { Menu } from "../common/Menu";
import { useApi } from "../../api/useApi";
import { ProductVariationColor } from "../../domain/Product";
import { ColorPicker } from "../common/ColorPicker";
import { TextToggledit } from "../common/TextToggledit";

import { selectColor, ColorState, colorsActions } from "./colors.state";

import "./ColorCard.scss";
import { useTranslation } from "react-i18next";

export const ColorCard: React.FC<{ id: ColorState["id"] }> = (props) => {
  const color = useSelector(selectColor(props.id));
  const dispatch = useDispatch();
  const api = useApi();
  const { t } = useTranslation();

  if (!color) {
    return null;
  }

  const updateColor = (change: Partial<ProductVariationColor>) => {
    api.updateColor(color.id)({ ...color, ...change });
  };

  const deleteColor = (id: number) => {
    Swal.fire({
      title: t(
        "Are you sure you want to delete this color?",
        "Are you sure you want to delete this color?"
      ),
      showCancelButton: true,
      confirmButtonColor: "#272626",
      confirmButtonText: t("Delete", "Delete"),
      cancelButtonText: t("Cancel", "Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .deleteColor(id)
          .then(() => dispatch(colorsActions.removed(color.id)))
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              let errors = "";
              const keysErrors = Object.keys(error.response.data);
              if (keysErrors.length > 0) {
                for (let index = 0; index < keysErrors.length; index++) {
                  errors += error.response.data[keysErrors[index]] + "\n";
                }
              }
              Swal.fire(
                t("Error!", "Error!"),
                errors ||
                  t(
                    "An error ocurred, please review the inserted data.",
                    "An error ocurred, please review the inserted data."
                  ),
                "error"
              );
            }

            throw Promise.reject(error);
          });
      }
    });
  };

  return (
    <Card className="color-card">
      <CardContent>
        <TextToggledit
          name="color-name"
          text={color.name}
          onChange={(name) => {
            dispatch(colorsActions.nameChanged({ id: color.id, name }));
            return updateColor({ name });
          }}
        />

        <div className="colors">
          <Tooltip title={String(t("Primary color", "Primary color"))}>
            <span>
              <ColorPicker
                className="color-picker"
                color={color.color}
                onChange={(newColor) => {
                  dispatch(
                    colorsActions.colorChanged({
                      id: color.id,
                      color: newColor,
                    })
                  );
                }}
                onClose={(newColor) => {
                  updateColor({ color: newColor });
                }}
              />
            </span>
          </Tooltip>

          <div className="secondary-color">
            <Tooltip title={String(t("Secondary color", "Secondary color"))}>
              <span>
                <ColorPicker
                  className="color-picker"
                  color={color.extraColor}
                  onChange={(newColor) => {
                    dispatch(
                      colorsActions.extraColorChanged({
                        id: color.id,
                        extraColor: newColor,
                      })
                    );
                  }}
                  onClose={(newColor) => {
                    updateColor({ extraColor: newColor });
                  }}
                />
              </span>
            </Tooltip>
            <FontAwesomeIcon
              className="icon-button"
              icon={faBroom}
              onClick={() => {
                dispatch(
                  colorsActions.extraColorChanged({
                    id: color.id,
                    extraColor: null,
                  })
                );
                updateColor({ extraColor: null });
              }}
            />
          </div>
        </div>
      </CardContent>
      <CardActions className="size-actions">
        <Menu
          icon={faEllipsisH}
          render={({ close }) => {
            const onDelete = () => {
              deleteColor(color.id);
              close();
            };

            return (
              <MenuItem onClick={onDelete}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faTrash} />
                </ListItemIcon>
                <ListItemText primary={t("Delete", "Delete")} />
              </MenuItem>
            );
          }}
        ></Menu>
      </CardActions>
    </Card>
  );
};
