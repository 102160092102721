import {
  faEllipsisH,
  faTrash,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListItemIcon,
  ListItemText,
  Card,
  CardActions,
  CardContent,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { useApi } from "../../api/useApi";
import { Imagedit } from "../common/Imageedit/Imagedit";
import { ImageditModal } from "../common/Imageedit/ImageditModal";
import { Menu } from "../common/Menu";
import { Overlay } from "../common/Overlay";
import { SmartModal } from "../common/SmartModal";
import { TextToggledit } from "../common/TextToggledit";

import { selectTexture, TextureState, texturesActions } from "./textures.state";

import "./TextureCard.scss";
import { t } from "i18next";

export const TextureCard: React.FC<{ id: TextureState["id"] }> = (props) => {
  const texture = useSelector(selectTexture(props.id));
  const dispatch = useDispatch();
  const api = useApi();

  if (!texture) {
    return null;
  }

  const updateTexture = (changes: Partial<TextureState>) => {
    api.updateTexture(texture.id)({ ...texture, ...changes });
  };

  const deleteTexture = (id: number) => {
    Swal.fire({
      title: t(
        "Are you sure you want to delete this texture?",
        "Are you sure you want to delete this texture?"
      ),
      showCancelButton: true,
      confirmButtonColor: "#272626",
      confirmButtonText: t("Delete", "Delete"),
      cancelButtonText: t("Cancel", "Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .deleteTexture(id)
          .then(() => dispatch(texturesActions.removed(texture.id)))
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              let errors = "";
              const keysErrors = Object.keys(error.response.data);
              if (keysErrors.length > 0) {
                for (let index = 0; index < keysErrors.length; index++) {
                  errors += error.response.data[keysErrors[index]] + "\n";
                }
              }
              Swal.fire(
                t("Error!", "Error!"),
                errors ||
                  t(
                    "An error ocurred, please review the inserted data.",
                    "An error ocurred, please review the inserted data."
                  ),
                "error"
              );
            }

            throw Promise.reject(error);
          });
      }
    });
  };

  return (
    <Card className="texture-card">
      <CardContent>
        <TextToggledit
          name="texture-name"
          text={texture.name}
          onChange={(name) => {
            dispatch(texturesActions.nameChanged({ id: texture.id, name }));

            updateTexture({ name });
          }}
        />

        <SmartModal
          body={({ closeModal }) => (
            <div>
              <ImageditModal
                allowEmpty={false}
                desiredDimensions={{ width: 48, height: 48 }}
                helpDimensions={{ width: 50, height: 50 }}
                noCrop
                onSave={(images) => {
                  if (images.length > 0 && images[0].file) {
                    dispatch(
                      texturesActions.pictureChanged({
                        id: texture.id,
                        pictureTexture: images[0].src,
                      })
                    );
                    updateTexture({ nova_imagem: images[0].file });
                  }
                  closeModal();
                }}
                title={t("Edit texture", "Edit texture")}
                src={[
                  {
                    id: "123",
                    src: texture.pictureTexture,
                    originalImage: texture.pictureTexture,
                    crop: {},
                  },
                ]}
              ></ImageditModal>
            </div>
          )}
        >
          <Overlay
            className="texture-image-container"
            render={() => <FontAwesomeIcon icon={faPencilAlt} />}
          >
            <img src={texture.pictureTexture} className="texture-image" />
          </Overlay>
        </SmartModal>
      </CardContent>
      <CardActions className="texture-actions">
        <Menu
          icon={faEllipsisH}
          render={({ close }) => {
            const onDelete = () => {
              deleteTexture(texture.id);
              close();
            };

            return (
              <MenuItem onClick={onDelete}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faTrash} />
                </ListItemIcon>
                <ListItemText primary={t("Delete", "Delete")} />
              </MenuItem>
            );
          }}
        ></Menu>
      </CardActions>
    </Card>
  );
};
