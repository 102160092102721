import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, useFormikContext } from "formik";
import React, { useEffect, useState, useRef } from "react";
import registerAccessGtm from "../services/gtmRegister";

import { useApi } from "../api/useApi";
import { FormErrors } from "../common";
import { BrandProfile, InfoBlock, SiteArea } from "../domain/BrandProfile";
import { BottomBar } from "../external/common/BottomBar";
import { noop } from "../external/common/helpers";
import { Prompt } from "react-router-dom";
import {
  Imagedit,
  ImageditRenderProps,
} from "../external/common/Imageedit/Imagedit";
import { MainHeader } from "../external/common/MainHeader";
import { Tab } from "../external/common/TabbedView/Tab";
import { TabbedView } from "../external/common/TabbedView/TabbedView";
import { TabSelector } from "../external/common/TabbedView/TabSelector";
import { TabSwitch } from "../external/common/TabbedView/TabSwitch";
import { TextToggledit } from "../external/common/TextToggledit";
import { Footer } from "../external/Footer/Footer";
import { History } from "../external/History/History";
import { TheBrand } from "../external/TheBrand/TheBrand";
import { useAsync } from "../external/useAsync";

import { PageLoadingSpinner } from "./common/PageLoadingSpinner";

import "./BrandEdit.scss";
import getCroppedImg from "../external/getCroppedImg";
import { useTranslation } from "react-i18next";
import { BottomBarBrandPage } from "../external/common/BottomBarBrandPage";
import { Modal } from "../external/common/Modal";
import { PrimaryButton } from "../common/buttons/PrimaryButton";
import { LoadingSpinner } from "../components/LoadingSpinner";
import Swal from "sweetalert2";

type BrandProfileForm = BrandProfile;

const emptyInfoBlock = (
  args: Pick<InfoBlock, "seller" | "featured" | "siteArea">
): InfoBlock => ({
  id: Math.random(),
  title: "",
  summary: "",
  text: "",
  buttonLabel: "",
  link: "",
  images: [],
  imagePosition: "left",
  imageOrientation: "landscape",
  order: 0,
  seller: args.seller,
  featured: args.featured,
  siteArea: args.siteArea,
});

const CoverPhoto: React.FC = (props) => {
  const { values, setFieldValue } = useFormikContext<BrandProfile>();
  const [crop, setCrop] = useState<any>({})
  const coverImageCropped = values.coverImageThumbnail.url;
  const coverImageFull = values.coverImage.url;
  const logoImage = values.logoBrandPage.url;
  const [imgSrc, setImgSrc] = useState(coverImageFull);
  const { t } = useTranslation();

  const transformCrop = (image: any) => {
    return new Promise((resolve) => {
      if (!image.imageCropping) {
        resolve(image.cropOriginal);
      }
      let img = new Image();
      img.onload = () => {
        let auxImage: any = { ...image };
        const crop: any = auxImage?.imageCropping?.split(",");
        if (crop) {
          auxImage.crop = {
            ...auxImage?.crop,
            aspect: 605 / 417,
            unit: "%",
            x: (crop[0] / img.width) * 100,
            y:
              (crop[3] / img.height) * 100 -
              ((crop[3] - crop[1]) / img.height) * 100,
            height: ((crop[3] - crop[1]) / img.height) * 100,
            width: ((crop[2] - crop[0]) / img.width) * 100,
          };
          resolve(auxImage.crop);
        }
      };
      img.src = image?.imageOriginal;
    });
  };

  useEffect(() => {
    const load = async () => {
      const currentCrop = await transformCrop(values.coverImage);

      setCrop(currentCrop)
    }

    load()
  }, [])

  return (
    <div className="cover-image-container">
      {props.children}
      <Imagedit
        allowEmpty={false}
        title={t("Edit cover image", "Edit cover image")}
        desiredDimensions={{ width: 1920, height: 620 }}
        className="cover-image-imagedit"
        zIndex={1}
        src={{
          id: "1",
          src: coverImageFull || "",
          originalImage: values.coverImage.imageOriginal || "",
          imageCropping: values.coverImage.imageCropping,
          crop: crop,
        }}
        onSave={(images) => {
          setCrop(images[0].cropOriginal)
          let img = new Image();
          img.onload = async () => {
            const croppedImg: any = await getCroppedImg(img, images[0].crop);
            var objectURL = URL.createObjectURL(croppedImg);
            setImgSrc(objectURL);
          };
          img.crossOrigin = "anonymous";
          if (!images[0].file) {
            img.src = images[0].src + `?x=123`;
          } else {
            img.src = images[0].src;
          }

          setFieldValue("coverImageThumbnail", {
            ...values.coverImageThumbnail,
            url: images[0].src,
            nova_imagem: images[0].file,
            nova_imagem_crop: images[0].crop,
          });

          setFieldValue("coverImage", {
            ...values.coverImage,
            url: images[0].src,
            nova_imagem: images[0].file,
            nova_imagem_crop: images[0].crop,
          });
        }}
        overlay={() => (
          <div
            className="imagedit-overlay cover-image-overlay"
            style={{
              zIndex: 2,
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </div>
        )}
        render={({ isHovered: isCoverHovered }: ImageditRenderProps) => (
          <div
            className="cover-image"
            style={{
              backgroundImage: `url(${imgSrc})`,
            }}
          ></div>
        )}
      ></Imagedit>
    </div>
  );
};

const onValidate = (values: BrandProfileForm) => {
  const errors: FormErrors<BrandProfileForm> = {};

  if (!values.name) {
    errors.name = "Required";
  }

  return errors;
};

export const BrandEdit: React.FC = () => {
  const { t } = useTranslation();
  const [brandProfile, setBrandProfile] = useState<BrandProfile | undefined>(
    undefined
  );
  const [draftStatus, setDraftStatus] = useState<any>(null);
  const [showModalSubmit, setShowModalSubmit] = useState<boolean>(false);
  const [isLoadingSubmitReview, setIsLoadingSubmitReview] =
    useState<boolean>(false);
  const {
    user,
    fetchBrandProfile,
    updateBrandProfile,
    updatingBrand,
    infoBlocksSubmitting,
    submitForReview,
  } = useApi();
  const submitForReviewAsync = useAsync(submitForReview);
  const fetchBrand = useAsync((brandId: number) =>
    Promise.all([fetchBrandProfile()])
  );
  const updateBrandProfileAsync = useAsync<BrandProfile, any>((values) =>
    updateBrandProfile()(values)
  );

  useEffect(() => {
    registerAccessGtm()
  }, [])

  const formRef = useRef<any>();

  const onSave = (values: BrandProfileForm) => {
    updatingBrand(true);

    updateBrandProfileAsync
      .start(values)
      .then((res) => {
        let obj = res.data.brand_style_images.map((item: any) => ({
          id: item.id,
          title: item.title,
          imageSrc: item.image_580_600,
          originalImage: item.image_original,
          imageCropping285: item.image_285_cropping,
          imageCropping580: item.image_580_600_cropping,
          link: item.link,
          button_label: item.button_label,
        }));

        let objFeaturedInfoBlock = res.data.featured_info_block.images.map(
          (item: any) => ({
            featuredImage: item.featured_image,
            featuredImageResp: item.featured_image_resp,
            id: item.id,
            imageCropping: item.featured_image_cropping,
            imageResp: item.image_resp,
            landscapeImage: item.landscape_image,
            originalImage: item.original_image,
            portraitImage: item.portrait_image,
            videoUrl: item.video_url,
          })
        );

        formRef.current.setFieldValue("theBrand.brandStyle.images", obj);
        formRef.current.setFieldValue(
          "theBrand.featuredInfoBlock.images",
          objFeaturedInfoBlock
        );

        formRef.current.resetForm({
          values: {
            ...values,
            theBrand: {
              ...values.theBrand,
              brandStyle: { ...values.theBrand.brandStyle, images: obj },
              featuredInfoBlock: {
                ...values.theBrand.featuredInfoBlock,
                images: objFeaturedInfoBlock,
              },
            },
          },
        });
        setDraftStatus(res.data.draft_status);
      })
      .finally(() => updatingBrand(false))
      .catch(noop);
  };

  const handleSubmitForReview = async () => {
    setIsLoadingSubmitReview(true);
    try {
      await submitForReviewAsync.start(undefined);
      setShowModalSubmit(false);
      setIsLoadingSubmitReview(false);
      setDraftStatus("waiting_for_approval");
      Swal.fire({
        icon: "success",
        title: "Brand Page changes requested.",
        showConfirmButton: false,
        timer: 3500,
      });
    } catch (error) {
      setIsLoadingSubmitReview(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrand
      .start(user.brandId)
      .then(([profile]) => {
        if (profile) {
          localStorage.setItem("brand_slug", profile.slug);
          return {
            ...profile,
            theBrand: {
              ...profile.theBrand,
              featuredInfoBlock:
                profile.theBrand.featuredInfoBlock ||
                emptyInfoBlock({
                  seller: user.brandId,
                  featured: true,
                  siteArea: SiteArea.TheBrand,
                }),
              infoBlock: profile.theBrand.infoBlock,
              brandStyle: profile.theBrand.brandStyle,
            },
            history: {
              ...profile.history,
              details: profile.history.details,
            },
            callToAction: profile.callToAction,
          };
        }
      })
      .then((resp) => {
        setDraftStatus(resp?.draft_status);
        setBrandProfile(resp);
      })
      .catch(noop);
  }, []);

  if (fetchBrand.isPending) {
    return <PageLoadingSpinner />;
  }
  if (!brandProfile) {
    return null;
  }

  return (
    <Formik
      initialValues={brandProfile}
      innerRef={formRef}
      validate={onValidate}
      onSubmit={onSave}
    >
      {(formHelpers) => {
        const { values, handleSubmit, setFieldValue, dirty } = formHelpers;

        const submittingBrand =
          updateBrandProfileAsync.isPending ||
          infoBlocksSubmitting.find(
            (item) => item !== null && item !== undefined && item === true
          ) === true;

        return (
          <div className="edit-brand-profile">
            <Prompt
              when={!!dirty}
              message={(location) =>
                `${t(
                  "You have unsaved changes",
                  "You have unsaved changes"
                )}. ${t(
                  "Are you sure you want leave?",
                  "Are you sure you want leave?"
                )}`
              }
            />
            <Modal
              isOpen={showModalSubmit}
              contentLabel={t("Submit for review", "Submit for review")}
              onRequestClose={() => setShowModalSubmit(false)}
            >
              <h2>{t("Submit for review", "Submit for review")}</h2>
              <div className="international-retailers-modal-content">
                <p>
                  Your copy has been submitted for review. During this time you
                  <br />
                  won't be able to make any changes.
                </p>
              </div>
              <div
                className="international-retailers-modal-actions save-button"
                style={{ display: "flex", gap: "1rem" }}
              >
                <PrimaryButton
                  disabled={isLoadingSubmitReview}
                  onClick={handleSubmitForReview}
                >
                  {isLoadingSubmitReview ? (
                    <LoadingSpinner />
                  ) : (
                    t("Submit for review", "Submit for review")
                  )}
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => setShowModalSubmit(false)}
                  style={{ backgroundColor: "#b32020" }}
                >
                  Not ready
                </PrimaryButton>
              </div>
            </Modal>
            <form onSubmit={handleSubmit}>
              <TabbedView defaultTab="theBrand">
                <CoverPhoto>
                  <MainHeader>
                    <TextToggledit
                      text={values.name}
                      placeholder={t("Brand name", "Brand name")}
                      name="name"
                      onChange={(value) => setFieldValue("name", value)}
                    />
                  </MainHeader>
                </CoverPhoto>
                <div className="tab-menu">
                  <TabSelector id="theBrand">
                    {t("The Brand", "The Brand")}
                  </TabSelector>
                  <TabSelector id="history">
                    {t("History", "History")}
                  </TabSelector>
                </div>
                <TabSwitch>
                  <Tab id="theBrand">
                    <TheBrand />
                  </Tab>
                  <Tab id="history">
                    <History />
                  </Tab>
                </TabSwitch>
                <Footer />
              </TabbedView>
              <BottomBarBrandPage
                isSubmitting={submittingBrand}
                draftStatus={draftStatus}
                handleSubmitReview={setShowModalSubmit}
                message={
                  submittingBrand
                    ? ""
                    : updateBrandProfileAsync.isFailure
                    ? t("Error while saving brand", "Error while saving brand")
                    : updateBrandProfileAsync.isSuccess
                    ? t("Brand successfully saved", "Brand successfully saved")
                    : ""
                }
                isFailure={updateBrandProfileAsync.isFailure}
              />
            </form>
          </div>
        );
      }}
    </Formik>
  );
};
