import classnames from 'classnames';
import React, { useState } from 'react';

import { stopPropagation } from './helpers';

import './Overlay.scss';

interface OverlayProps {
    className?: string;
    render: () => JSX.Element;
}

export const Overlay: React.FC<OverlayProps> = props => {
    const [isHovered, setHovered] = useState(false);

    return (
        <div
            className={classnames('overlay-container', props.className)}
            onMouseOver={stopPropagation(() => setHovered(true))}
        >
            <div
                className={classnames('overlay', {
                    'overlay-hidden': !isHovered,
                })}
                onMouseOut={stopPropagation(() => setHovered(false))}
            >
                {props.render()}
            </div>
            {props.children}
        </div>
    );
};
