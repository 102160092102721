import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { Collection } from "../../domain/Collection";
import { selectCollections } from "../Collection/collections.state";

import { selectProductCollection, productActions } from './state/product.state';
import './ProductCollections.scss';

export const ProductCollections: React.FC = () => {
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const productCollection = useSelector(selectProductCollection);
  const collections = useSelector(selectCollections);
  const { t } = useTranslation();

  return (
    <div className="product-collections">
      <div className="product-label" style={{ fontWeight: "bolder" }}>
        {t("Collection", "Collection")}
      </div>
      <Autocomplete
        id="tags-standard"
        options={collections}
        getOptionLabel={(collection: Collection) => collection.name}
        getOptionSelected={(option, collection) =>
          option?.id === collection?.id
        }
        defaultValue={productCollection as any}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="standard"
            label=""
            placeholder={t("Select a collection", "Select a collection")}
          />
        )}
        onChange={(event, value) => {
          setFieldValue("collection", value);
          dispatch(productActions.changeCollection(value || undefined));
        }}
      />
    </div>
  );
};
