import React, { useState } from "react";

import { PrimaryButton } from "../common/PrimaryButton";
import { AsyncButton } from "../common/AsyncButton";
import { useApi } from "../../api/useApi";
import { noop } from "../common/helpers";
import { useAsync } from "../useAsync";

import "./AddTexture.scss";
import { Overlay } from "../common/Overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { ImageditModal } from "../common/Imageedit/ImageditModal";
import { SmartModal } from "../common/SmartModal";
import { useTranslation } from "react-i18next";

export const AddNewTexture: React.FC<{
  onSave: (args: {
    id: number;
    name: string;
    pictureTexture: string;
    nova_imagem?: File;
  }) => void;
}> = (props) => {
  const [name, setName] = useState("");
  const [pictureTexture, setPictureTexture] = useState("");
  const [nova_imagem, setNovaImagem] = useState<File>({} as File);

  const nameIsValid = name.length > 0;
  const pictureIsValid = pictureTexture.length > -1;

  const { user, createTexture } = useApi();
  const createTextureAsync = useAsync(createTexture(user.brandId));
  const { t } = useTranslation();

  const onSave = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (nameIsValid && pictureIsValid) {
      createTextureAsync
        .start({ name, pictureTexture, nova_imagem })
        .then((res) => {
          props.onSave({
            id: res.id,
            name,
            pictureTexture,
            nova_imagem: nova_imagem,
          });
        })
        .catch(noop);
    }
  };

  return (
    <form className="add-texture-form" onSubmit={onSave}>
      <h3>{t("Add new texture", "Add new texture")}</h3>
      <input
        type="text"
        className="texture-name-input"
        placeholder={t("Name", "Name")}
        required
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      <SmartModal
        body={({ closeModal }) => (
          <div>
            <ImageditModal
              allowEmpty={false}
              desiredDimensions={{ width: 48, height: 48 }}
              helpDimensions={{ width: 50, height: 50 }}
              noCrop
              onSave={(images) => {
                if (images[0].src && images[0].file) {
                  setNovaImagem(images[0].file);
                  setPictureTexture(images[0].src);
                  closeModal();
                }
              }}
              title={t("Edit texture", "Edit texture")}
              src={[
                {
                  id: "123",
                  src: pictureTexture,
                  crop: {},
                },
              ]}
            ></ImageditModal>
          </div>
        )}
      >
        <Overlay
          className="texture-image-container"
          render={() => <FontAwesomeIcon icon={faPencilAlt} />}
        >
          <img src={pictureTexture} className="texture-image" />
        </Overlay>
      </SmartModal>

      <div className="save-button">
        <AsyncButton isPending={createTextureAsync.isPending}>
          {t("Save", "Save")}
        </AsyncButton>
      </div>
    </form>
  );
};
