import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useApi } from "../api/useApi";
import { Size } from "../domain/Product";
import { noop } from "../external/common/helpers";
import { SizeList } from "../external/Size/SizeList";
import { sizesActions } from "../external/Size/sizes.state";
import { useAsync } from "../external/useAsync";

import { Page } from "./common/Page";
import { PageError } from "./common/PageError";
import { PageLoadingSpinner } from "./common/PageLoadingSpinner";
import registerAccessGtm from "../services/gtmRegister";

export const SizeListPage: React.FC = () => {
  const dispatch = useDispatch();
  const { user, fetchSizes } = useApi();
  const [sizes, setSizes] = useState<Size[]>([]);
  const { t } = useTranslation();

  const { isPending, isSuccess, isFailure, start } = useAsync(fetchSizes);
  useEffect(() => {
    start(user.brandId)
      .then((sizes) => {
        dispatch(sizesActions.fetched(sizes));
        setSizes(sizes);
      })
      .catch(noop);
  }, []);

  useEffect(() => {
    registerAccessGtm()
  }, [])

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  if (isFailure) {
    return <PageError message={t("Error fetching data", "Error fetching data")} />;
  }

  if (isSuccess) {
    return (
      <Page className="size-management">
        <div className="page-title">
          {t("Size management", "Size management")}
        </div>
        <SizeList initialSizes={sizes} />
      </Page>
    );
  }

  return null;
};
