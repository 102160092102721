import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { Sizechart } from "../../domain/Sizechart";
import { selectSizecharts } from "../Sizechart/sizechart.state";

import { selectProductSizechart, productActions } from "./state/product.state";
import './ProductSizecharts.scss';

export const ProductSizecharts: React.FC = () => {
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const productSizechart = useSelector(selectProductSizechart);
  const sizecharts = useSelector(selectSizecharts);
  const { t } = useTranslation();

  return (
    <div className="product-collections">
      <div className="product-label">{t("Size chart", "Size chart")}</div>
      <Autocomplete
        id="tags-standard"
        options={sizecharts}
        getOptionLabel={(sizechart: Sizechart) => sizechart.name}
        getOptionSelected={(option, sizechart) => option?.id === sizechart?.id}
        defaultValue={
          sizecharts.filter((sizechart) => sizechart.id === productSizechart)[0]
        }
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="standard"
            label=""
            placeholder={t("Select a size chart", "Select a size chart")}
          />
        )}
        onChange={(event, value) => {
          setFieldValue("sizechart_id", value?.id);
        }}
      />
    </div>
  );
};
