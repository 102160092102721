import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { useApi } from "../api/useApi";
import { Product } from "../domain/Product";
import { collectionsActions } from "../external/Collection/collections.state";
import { colorsActions } from "../external/Color/colors.state";
import { BottomBar } from "../external/common/BottomBar";
import { noop } from "../external/common/helpers";
import { measurementsActions } from "../external/Measurement/measurements.state";
import { ProductEdit } from "../external/Products/ProductEdit";
import { categoriesActions } from "../external/Products/state/categories.state";
import { detailsActions } from "../external/Products/state/details.state";
import { productActions } from "../external/Products/state/product.state";
import { segmentsActions } from "../external/Products/state/segments.state";
import { stocksActions } from "../external/Products/state/stocks.state";
import { variationsActions } from "../external/Products/state/variations.state";
import { sizesActions } from "../external/Size/sizes.state";
import { sizechartsActions } from "../external/Sizechart/sizechart.state";
import { texturesActions } from "../external/Texture/textures.state";
import { useAsync } from "../external/useAsync";
import { isNil, transformProduct } from "../helpers";
import { useRoutes } from "../useRoutes";

import { Page } from "./common/Page";
import { PageError } from "./common/PageError";
import { PageLoadingSpinner } from "./common/PageLoadingSpinner";
import i18next from "i18next";
import registerAccessGtm from "../services/gtmRegister";

const ProductSchema = Yup.object().shape({
  name: Yup.string().required(),
});
const newProduct = {
  name: i18next.t(
    "New product",
    "New product"),
  description: "",
  isActive: false,
  referenceCode: "",
  internalName: "",
  wholesalePrice: {
    currency: "USD",
    amount: 0,
  },
  retailerPrice: {
    currency: "USD",
    amount: 0,
  },
  orderMinimumQuantity: 0,
  orderMaximumQuantity: 0,
  orderDeliveryDays: 0,
  unitWeight: 0,
  sizechart_id: "",
  variations: [],
  sizes: [],
  details: [],
  categories: [],
  segments: [],
};

export const ProductCreatePage: React.FC = () => {
  const routes = useRoutes();
  const [status, setStatus] = useState<"pending" | "success" | "failure">(
    "pending"
  );
  const { t } = useTranslation();
  const {
    user,
    createProduct,
    fetchCollections,
    fetchSegments,
    fetchCategories,
    fetchMeasurements,
    fetchColors,
    fetchSizecharts,
    fetchSizes,
    fetchTextures,
  } = useApi();
  const createProductAsync = useAsync(createProduct(user.brandId));

  const isPending = status === "pending";
  const isFailure = status === "failure";
  const isSuccess = status === "success";

  const dispatch = useDispatch();

  useEffect(() => {
    registerAccessGtm()
  }, [])

  useEffect(() => {
    setStatus("pending");
    dispatch(productActions.added());
    Promise.all([
      fetchSizes(user.brandId),
      fetchColors(user.brandId),
      fetchTextures(user.brandId),
      fetchMeasurements(user.brandId),
      fetchSegments(),
      fetchCategories(),
      fetchCollections(user.brandId),
      fetchSizecharts(user.brandId),
    ])
      .then(
        ([
          sizes,
          colors,
          textures,
          measurements,
          segments,
          categories,
          collections,
          sizecharts,
        ]) => {
          dispatch(sizesActions.fetched(sizes));
          dispatch(colorsActions.fetched(colors));
          dispatch(texturesActions.fetched(textures));
          dispatch(measurementsActions.fetched(measurements));
          dispatch(sizechartsActions.fetched(sizecharts));
          dispatch(segmentsActions.segmentsFetched(segments));
          dispatch(categoriesActions.categoriesFetched(categories));
          dispatch(collectionsActions.fetched(collections.results as any));
          setStatus("success");
        }
      )
      .catch(() => setStatus("failure"));
  }, []);

  const createProductProcess = async (fields: any): Promise<Product["id"]> => {
    if (isNil(fields)) {
      throw new Error();
    }

    const productId = await createProductAsync.start(fields);
    return productId;
  };
  const createProductProcessAsync = useAsync(createProductProcess);

  const onCreate = (fields: any) =>
    createProductProcessAsync
      .start(fields)
      .then((id) => {
        if (fields.addAnother === true) {
          routes.products.reload();
        } else {
          routes.products.navigateById(id);
        }
      })
      .catch(noop);

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  if (isFailure) {
    return <PageError message={t(
      "Error fetching data",
      "Error fetching data")}
       />;
  }

  if (isSuccess) {
    return (
      <>
        <Formik
          initialValues={newProduct}
          validationSchema={ProductSchema}
          onSubmit={onCreate}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Page className="product-create-page">
                <div className="page-title">
                  {t("Create new product", "Create new product")}
                </div>
                <ProductEdit />
              </Page>
              <BottomBar
                label={t("Save Product", "Save Product")}
                addAnother={true}
                isSubmitting={createProductAsync.isPending}
                message={
                  createProductAsync.isFailure
                    ? t(
                        "Error while saving product",
                        "Error while saving product"
                      )
                    : createProductAsync.isSuccess
                    ? t(
                        "Product successfully saved",
                        "Product successfully saved"
                      )
                    : ""
                }
                isFailure={createProductAsync.isFailure}
              />
            </form>
          )}
        </Formik>
      </>
    );
  }

  return null;
};

export const ProductEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const formRef = useRef<any>();
  const {
    user,
    updateProduct,
    fetchCollections,
    fetchSegments,
    fetchCategories,
    fetchMeasurements,
    fetchColors,
    fetchProduct,
    fetchSizecharts,
    fetchSizes,
    fetchTextures,
  } = useApi();
  const dispatch = useDispatch();
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    registerAccessGtm()
  },[])

  const { isPending, isFailure, isSuccess, start } = useAsync(async () =>
    Promise.all([
      fetchProduct(+id),
      fetchSizes(user.brandId),
      fetchColors(user.brandId),
      fetchTextures(user.brandId),
      fetchMeasurements(user.brandId),
      fetchSegments(),
      fetchCategories(),
      fetchCollections(user.brandId),
      fetchSizecharts(user.brandId),
    ])
  );

  const updateProductAsync = useAsync(updateProduct(+id));

  useEffect(() => {
    start({})
      .then(
        ([
          product,
          sizes,
          colors,
          textures,
          measurements,
          segments,
          categories,
          collections,
          sizecharts,
        ]) => {
          dispatch(
            productActions.fetched({
              ...product,
              details: product.details,
              categories: product.categories,
              segments: product.segments,
              variations: product.variations.map((variation) => variation.id),
              sizes: product.sizes.map((size) => size.id),
            })
          );
          dispatch(sizesActions.fetched(sizes));
          dispatch(colorsActions.fetched(colors));
          dispatch(texturesActions.fetched(textures));
          dispatch(measurementsActions.fetched(measurements));
          dispatch(sizechartsActions.fetched(sizecharts));
          dispatch(
            variationsActions.variationsFetched(product.variations as any)
          );
          dispatch(segmentsActions.segmentsFetched(segments));
          dispatch(categoriesActions.categoriesFetched(categories));
          dispatch(collectionsActions.fetched(collections.results as any));

          setProduct(product);
        }
      )
      .catch(noop);
  }, [id]);

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  if (isFailure) {
    return (
      <PageError message={t("Error fetching data", "Error fetching data")} />
    );
  }

  const onSave = (fields: any) => {
    updateProductAsync.start(fields).then((response: any) => {
      return formRef.current.setValues(transformProduct(response.data));
    });
  };

  if (isSuccess && product) {
    return (
      <Formik
        innerRef={formRef}
        initialValues={product}
        validationSchema={ProductSchema}
        onSubmit={onSave}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Page className="product-edit-page">
              <div className="page-title">
                {t("Edit product", "Edit product")}
              </div>
              <ProductEdit />
            </Page>
            <BottomBar
              label={t("Save Product", "Save Product")}
              isSubmitting={updateProductAsync.isPending}
              message={
                updateProductAsync.isFailure
                  ? t(
                      "Error while saving product",
                      "Error while saving product"
                    )
                  : updateProductAsync.isSuccess
                  ? t(
                      "Product successfully saved",
                      "Product successfully saved"
                    )
                  : ""
              }
              isFailure={updateProductAsync.isFailure}
            />
          </form>
        )}
      </Formik>
    );
  }

  return null;
};
