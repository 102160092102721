import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton } from "../common/IconButton";
import { SmartModal } from "../common/SmartModal";
import { AddNewTexture } from "./AddTexture";
import { TextureCard } from "./TextureCard";
import { selectTextures, texturesActions } from "./textures.state";

import "./TextureList.scss";
import { useTranslation } from "react-i18next";

export const TextureList: React.FC = () => {
  const textures = useSelector(selectTextures);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <div className="texture-list">
        {textures.map((texture) => (
          <TextureCard key={texture.id} id={texture.id} />
        ))}
      </div>
      <SmartModal
        body={({ closeModal }) => (
          <AddNewTexture
            onSave={(args) => {
              dispatch(texturesActions.added(args));
              closeModal();
            }}
          />
        )}
      >
        <IconButton icon={faPlus}>
          {t("Add new texture", "Add new texture")}
        </IconButton>
      </SmartModal>
    </>
  );
};
