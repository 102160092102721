import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { IconButton } from "../common/IconButton";
import { SmartModal } from "../common/SmartModal";

import { AddNewColor } from "./AddColor";
import { ColorCard } from "./ColorCard";
import { selectColors, colorsActions } from "./colors.state";

import "./ColorList.scss";
import { useTranslation } from "react-i18next";

export const ColorList: React.FC = () => {
  const colors = useSelector(selectColors);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <div className="color-list">
        {colors.map((color) => (
          <ColorCard key={color.id} id={color.id} />
        ))}
      </div>

      <SmartModal
        body={({ closeModal }) => (
          <>
            <AddNewColor
              onSave={(args) => {
                dispatch(colorsActions.added(args));
                closeModal();
              }}
            />
          </>
        )}
      >
        <IconButton icon={faPlus}>
          {t("Add new color", "Add new color")}
        </IconButton>
      </SmartModal>
    </>
  );
};
