import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  ProductSummary,
  ProductSummaryVariation,
} from "../../domain/ProductList";
import { useRoutes } from "../../useRoutes";

import "./ProductList.scss";

const ProductCard: React.FC<{ product: ProductSummary }> = (props) => {
  const { product } = props;
  const routes = useRoutes();
  const { t } = useTranslation();

  function onProductClick(id: number) {
    routes.products.navigateById(id);
  }
  const variations = product.variations.map((variation) => (
    <>
      {variation.texture.pictureTexture !== undefined && (
        <div key={variation.id} className="product-variation">
          <img src={variation.texture.pictureTexture} />
          {variation.color?.color !== "" && (
            <span
              style={{
                display: "inline-block",
                position: "absolute",
                top: 0,
                borderLeft: "15px solid transparent",
                borderBottom: `15px solid ${variation.color?.color}`,
              }}
            />
          )}
        </div>
      )}
      {variation.texture.id === undefined && variation.color?.color !== "" && (
        <div key={variation.id} className="product-variation">
          <div
            style={{
              display: "inline-block",
              backgroundColor: variation.color?.color,
              width: "15px",
              height: "15px",
              position: "relative",
            }}
          >
            {variation.color?.extraColor !== "" && (
              <span
                style={{
                  display: "inline-block",
                  position: "absolute",
                  top: 0,
                  borderLeft: "15px solid transparent",
                  borderBottom: `15px solid ${variation.color?.extraColor}`,
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  ));

  const getFirstProductImage = (
    variations: ProductSummaryVariation[]
  ): string | undefined => {
    if (variations) {
      for (let index = 0; index < variations.length; index++) {
        const variation = variations[index];
        if (variation.images) {
          for (
            let indexImages = 0;
            indexImages < variation.images.length;
            indexImages++
          ) {
            const element = variation.images[indexImages];
            return element.pictureThumbnail;
          }
        }
      }
    }
  };

  return (
    <div className="product" onClick={() => onProductClick(product.id)}>
      <img
        className="product-image"
        src={getFirstProductImage(product.variations)}
      />
      <div className="product-name">{product.name}</div>
      <div className="product-variations">{variations}</div>
      <div className="product-price">
        <div className="product-price-wholesale">
          <b>{t("W", "W")}</b>{" "}| {product.wholesalePrice.currency}{" "}
          {product.wholesalePrice.amount}
        </div>
        <div className="product-price-retail">
          <b>{t("R", "R")}</b>{" "}| {product.retailerPrice.currency}{" "}
          {product.retailerPrice.amount}
        </div>
      </div>
    </div>
  );
};

export const ProductList: React.FC<{ products: ProductSummary[] }> = (
  props
) => {
  if (!props.products) {
    return null;
  }

  return (
    <div className="product-list">
      {props.products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
};
