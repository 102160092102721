import React, { useEffect, useState } from "react";

import { AsyncButton } from "./AsyncButton";
import { ErrorText } from "./ErrorMessage";

import "./BottomBar.scss";
import { useFormikContext } from "formik";
import { Product } from "../../domain/Product";
import { useTranslation } from "react-i18next";

export const BottomBar: React.FC<{
  isSubmitting: boolean;
  label: string;
  message?: string;
  isFailure?: boolean;
  addAnother?: boolean;
}> = (props) => {
  const isSubmitting = props.isSubmitting ?? false;
  const [isMsgShown, setMsgShown] = useState(false);
  const { setFieldValue, submitForm } = useFormikContext<Product>();

  useEffect(() => {
    setMsgShown(true);
    const timerId = setTimeout(() => {
      if (!props.isFailure) {
        setMsgShown(false);
      }
    }, 5000);
    return () => clearTimeout(timerId);
  }, [props.message, props.isFailure]);

  const handleAddAnother = () => {
    setFieldValue("addAnother", true);
    submitForm();
  };
  const { t } = useTranslation();

  return (
    <div className="bottom-bar">
      {isMsgShown ? (
        props.isFailure ? (
          <ErrorText message={props.message ?? ""} />
        ) : (
          props.message
        )
      ) : null}
      <AsyncButton type="submit" isPending={isSubmitting}>
        {props.label}
      </AsyncButton>
      {props.addAnother && (
        <AsyncButton
          onClick={handleAddAnother}
          type="button"
          isPending={isSubmitting}
        >
          {t("save and add another", "save and add another")}
        </AsyncButton>
      )}
    </div>
  );
};
