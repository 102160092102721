import { Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../api/useApi";
import { AsyncButton } from "../common/AsyncButton";
import { ColorPicker } from "../common/ColorPicker";
import { ErrorMessage } from "../common/ErrorMessage";
import { noop } from "../common/helpers";
import { ModalActions } from "../common/Modal";
import { useAsync } from "../useAsync";

import "./AddColor.scss";

export const AddNewColor: React.FC<{
  onSave: (args: {
    id: number;
    name: string;
    color: string;
    extraColor: string | null;
  }) => void;
}> = (props) => {
  const [name, setName] = useState("");
  const [color, setColor] = useState("#ffffff");
  const [extraColor, setExtraColor] = useState("");

  const { user, createColor } = useApi();
  const createColorAsync = useAsync(createColor(user.brandId));

  const nameIsValid = name.length > 0;
  const colorIsValid = color.length > 0;
  const { t } = useTranslation();

  const onSave = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (nameIsValid && colorIsValid) {
      createColorAsync
        .start({ name, color, extraColor })
        .then((res) => {
          props.onSave({ id: res.id, name, color, extraColor });
        })
        .catch(noop);
    }
  };

  return (
    <form className="add-color-form" onSubmit={onSave}>
      <h3>{t("Add new color", "Add new color")}</h3>

      {createColorAsync.isFailure ? (
        <ErrorMessage
          message={t(
            "Error while creating color",
            "Error while creating color"
          )}
        />
      ) : null}

      <input
        type="text"
        className="color-name-input"
        placeholder={t("Name", "Name")}
        required
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      <div className="pickers-container">
        <Tooltip title={String(t("Primary color", "Primary color"))}>
          <span>
            <ColorPicker
              className="color-picker"
              color={color}
              onChange={setColor}
            />
          </span>
        </Tooltip>

        <Tooltip title={String(t("Secondary color", "Secondary color"))}>
          <span>
            <ColorPicker
              className="color-picker"
              color={extraColor}
              onChange={setExtraColor}
            />
          </span>
        </Tooltip>
      </div>
      <div className="save-button">
        <AsyncButton isPending={createColorAsync.isPending}>
          {t("Save", "Save")}
        </AsyncButton>
      </div>
    </form>
  );
};
