import {Tooltip} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Menu from '../../../src/components/Header/Menu/Menu'


import {useRoutes} from '../../useRoutes';
import {API_URL} from '../../config';

import './Header.scss';

export const Header: React.FC = () => {
    const routes = useRoutes();
    const {t} = useTranslation();
    const [logoXAccount, setLogoXAccount] = useState('');

    const apiToken = localStorage.getItem('api_token');

    useEffect(() => {
        if (localStorage.getItem('X-Account-Logo')) {
            setLogoXAccount(localStorage.getItem('X-Account-Logo') || '');
        }
    }, []);

    return (
        <div className='header'>
            <div className='topbar'>
                <img className='logo' src={logoXAccount} />
                <div className='links'>
                    <Link to={routes.brandPage.url}>{t('Brand Page', 'Brand Page')}</Link>
                    <Link to={routes.collections.listUrl}>{t('Collections', 'Collections')}</Link>
                    <a href={`${API_URL}/a-dmin/products?token=${apiToken}`}  target='_blank'>{t('Products', 'Products')}</a>

                    <Link to={routes.dashboard.url}>{t('Analytics', 'Analytics')}</Link>

                    <Menu />
                </div>
            </div>
            <div className='bottombar'>
                <Link to={routes.colorsAndTextures.listUrl}>{t('Colors and Textures', 'Colors and Textures')}</Link>
                <Tooltip
                    title={String(t('You will be redirected to Admin Panel', 'You will be redirected to Admin Panel'))}
                >
                    <a target='_blank' href={`${API_URL}/a-dmin/linesheets?token=${apiToken}`}>
                        {t('Linesheets', 'Linesheets')}
                    </a>
                </Tooltip>
                <Link to={routes.sizes.listUrl}>{t('Sizes', 'Sizes')}</Link>
                {/* <Link to={routes.measurements.listUrl}>
          {t("Measurements", "Measurements")}
        </Link> */}
                <Link to={routes.sizecharts.listUrl}>{t('Size charts', 'Size charts')}</Link>
            </div>
        </div>
    );
};
