import { useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import { ProductDetails } from './ProductDetails';
import { ProductInfo } from './ProductInfo';
import { ProductSizes } from './ProductSizes';
import { ProductVariations } from './ProductVariations';
import { selectProduct } from './state/product.state';

import './ProductEdit.scss';

export const ProductEdit: React.FC = () => {
    return (
        <div className='product-edit'>
            <ProductInfo />
            <ProductSizes />
            {/* <ProductSizechart /> */}
            {/* <ProductDetails /> */}
            <ProductVariations />
        </div>
    );
};
