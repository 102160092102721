import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Product } from "../../domain/Product";
import { PrimaryButton } from "../common/PrimaryButton";
import { SmartModal } from "../common/SmartModal";
import { selectSizes, sizesActions } from "../Size/sizes.state";
import DualListBox from "react-dual-listbox";

import "react-dual-listbox/lib/react-dual-listbox.css";

import { selectProductSizes } from "./state/product.state";
import { useTranslation } from "react-i18next";
import "./ProductSizes.scss";

const AddNewSize: React.FC<{
  onSave: (args: { name: string }) => void;
}> = (props) => {
  const [name, setName] = useState("");
  const { t } = useTranslation();
  return (
    <div className="product-sizes">
      <h3>{t("Add new size", "Add new size")}</h3>
      <div className="input-container">
        <input
          type="text"
          className="size-name-input"
          placeholder={t("Name", "Name")}
          value={name}
          required
          onChange={(event) => setName(event.target.value)}
        />

        <div className="save-button">
          <PrimaryButton onClick={() => props.onSave({ name })}>
            {t("Save", "Save")}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export const ProductSizes: React.FC = () => {
  const dispatch = useDispatch();
  const sizes = useSelector(selectSizes);
  const productSizes = useSelector(selectProductSizes);

  const [selected, setSelected] = useState<string[]>(
    productSizes.map((size) =>
      JSON.stringify({
        id: size.id,
        name: size.name,
      })
    )
  );
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const { setFieldValue } = useFormikContext<Product>();

  const onChange = (value: string[]) => {
    setSelected(value);

    setFieldValue(
      "sizes",
      value.map((val) => JSON.parse(val))
    );
  };

  useEffect(() => {
    let opts: Array<any> = [];
    sizes.forEach((size) => {
      opts.push({
        label: size.name,
        value: JSON.stringify({
          id: size.id,
          name: size.name,
        }),
      });
    });
    setOptions(opts);
  }, [sizes]);

  const { t } = useTranslation();

  if (!sizes) {
    return null;
  }

  return (
    <div className="product-sizes">
      <div className="product-label" style={{ marginTop: "8px" }}>
        {t("Sizes", "Sizes")}
      </div>
      <DualListBox
        options={options}
        selected={selected}
        onChange={onChange}
        icons={{
          moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
          moveAllLeft: [
            <FontAwesomeIcon icon={faChevronLeft} />,
            <FontAwesomeIcon icon={faChevronLeft} />,
          ],
          moveRight: <FontAwesomeIcon icon={faChevronRight} />,
          moveAllRight: [
            <FontAwesomeIcon icon={faChevronRight} />,
            <FontAwesomeIcon icon={faChevronRight} />,
          ],
        }}
      />
      <SmartModal
        autoWidth
        body={({ closeModal }) => (
          <AddNewSize
            onSave={(args) => {
              dispatch(sizesActions.added(args));
              closeModal();
            }}
          />
        )}
      >
        <div className="icon-button">
          <FontAwesomeIcon icon={faPlus} />
          <span>{t("Create new size", "Create new size")}</span>
        </div>
      </SmartModal>
    </div>
  );
};
