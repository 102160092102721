import MenuItem from "@material-ui/core/MenuItem";
import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { Product } from "../../domain/Product";

import { FormSelect } from "../common/Select";
import { SwitcherRaw } from "../common/Switcher";
import { FormNumberInput } from "../common/TextInput";
import { FormTextToggledit } from "../common/TextToggledit";
import { TextField } from "@material-ui/core";
import DualListBox from "react-dual-listbox";

import "react-dual-listbox/lib/react-dual-listbox.css";

import { ProductCollections } from "./ProductCollections";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { selectCategories } from "./state/categories.state";
import { selectSegments } from "./state/segments.state";
import { ProductSizecharts } from "./ProductSizecharts";
import { useTranslation } from "react-i18next";

export const ProductInfo: React.FC = () => {
  const categories = useSelector(selectCategories);
  const segments = useSelector(selectSegments);
  const { t } = useTranslation();

  const { setFieldValue, values } = useFormikContext<Product>();

  const [categoriesOptions, setCategoriesOptions] = useState<
    { value: number; label: string }[]
  >(
    categories.map((category: any) => ({
      label: category.name,
      value: category.id,
    }))
  );
  const [categoriesSelected, setCategoriresSelected] = useState(
    values.categories
  );

  const [segmentsOptions, setSegmentsOptions] = useState<
    { value: number; label: string }[]
  >(
    segments.map((segment: any) => ({
      label: segment.name,
      value: segment.id,
    }))
  );
  const [segmentsSelected, setSegmentsSelected] = useState(values.segments);

  const onChangeCategory = (value: number[]) => {
    console.log(value);
    setCategoriresSelected(value);

    setFieldValue("categories", value);
  };

  const onChangeSegment = (value: number[]) => {
    console.log(value);
    setSegmentsSelected(value);

    setFieldValue("segments", value);
  };

  const handleDetail = (value: string, index: number) => {
    setFieldValue(`details[${index}]`, value);
  };

  const addDetail = () => {
    let auxDetails = [];
    auxDetails.push(...values.details);
    auxDetails.push("");
    setFieldValue("details", auxDetails);
  };

  const removeDetail = (index: number) => {
    let auxDetails = [];
    auxDetails.push(...values.details);
    auxDetails.splice(index, 1);
    setFieldValue("details", auxDetails);
  };

  return (
    <>
      <div className="product-name">
        <Field
          name="name"
          as={FormTextToggledit}
          placeholder={t("Product name", "Product name")}
        />
      </div>

      <div className="product-codes">
        <div className="product-reference-code">
          {t("REF", "REF")}:{" "}
          <Field name="referenceCode" as={FormTextToggledit} />
        </div>

        <div className="product-internal-name">
          {t("NCM/HS Code", "NCM/HS Code")}:{" "}
          <Field name="internalName" as={FormTextToggledit} />
        </div>
      </div>

      <div className="separator"></div>

      <div className="product-description">
        <Field
          name="description"
          as={FormTextToggledit}
          placeholder={t("ADD DESCRIPTION", "ADD DESCRIPTION")}
        />
      </div>

      <div className="section is-active">
        <Field
          name="isActive"
          as={SwitcherRaw}
          label={t("Is active", "Is active")}
        />
      </div>

      <div className="product-price-wholesale">
        <span className="product-label">{t("Wholesale", "Wholesale")} |</span>
        <Field name="wholesalePrice.currency" as={FormSelect}>
          <MenuItem value="USD">{t("US Dollar", "US Dollar")}</MenuItem>
          <MenuItem value="EUR">{t("EUR", "EUR")}</MenuItem>
          <MenuItem value="GBP">{t("GBP", "GBP")}</MenuItem>
        </Field>
        <Field
          name="wholesalePrice.amount"
          as={FormNumberInput}
          InputProps={{ inputProps: { step: "0.01" } }}
        />
      </div>
      <div className="product-price-retailer">
        <span className="product-label">
          {t("Suggested retail", "Suggested retail")} |
        </span>
        <Field name="retailerPrice.currency" as={FormSelect}>
          <MenuItem value="USD">{t("US Dollar", "US Dollar")}</MenuItem>
          <MenuItem value="EUR">{t("EUR", "EUR")}</MenuItem>
          <MenuItem value="GBP">{t("GBP", "GBP")}</MenuItem>
        </Field>
        <Field
          name="retailerPrice.amount"
          as={FormNumberInput}
          InputProps={{ inputProps: { step: "0.01" } }}
        />
      </div>
      <div className="product-shipping">
        <span className="product-label">
          {t("Expected to ship", "Expected to ship")} |
        </span>
        {t("up to", "up to")}{" "}
        <Field
          name="orderDeliveryDays"
          as={FormNumberInput}
          InputProps={{ inputProps: {} }}
        />{" "}
        {t("days", "days")}
      </div>
      <div className="product-weight">
        <span className="product-label">
          {t("Unit weight", "Unit weight")} |
        </span>
        <Field
          name="unitWeight"
          as={FormNumberInput}
          InputProps={{ inputProps: { step: "0.001" } }}
        />
      </div>
      <ProductCollections />
      <ProductSizecharts />

      <div className="product-details">
        <span className="product-label">{t("Details", "Details")}:</span>
        {values.details.map((detail, index) => (
          <div className="input-details-container">
            <TextField
              InputProps={{
                placeholder: t("Detail", "Detail"),
                type: "text",
                value: detail,
                className: "input-details",
              }}
              onChange={(e) => handleDetail(e.target.value, index)}
            />
            <FontAwesomeIcon
              onClick={() => removeDetail(index)}
              icon={faTrashAlt}
            />
          </div>
        ))}
        <span className="add-detail" onClick={addDetail}>
          {t("ADD DETAIL", "ADD DETAIL")} <FontAwesomeIcon icon={faPlus} />
        </span>
      </div>

      <div className="product-categories">
        <div className="product-label" style={{ marginTop: "8px" }}>
          {t("CATEGORIES", "CATEGORIES")}
        </div>
        <DualListBox
          options={categoriesOptions}
          selected={categoriesSelected}
          onChange={onChangeCategory}
          icons={{
            moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
            moveAllLeft: [
              <FontAwesomeIcon icon={faChevronLeft} />,
              <FontAwesomeIcon icon={faChevronLeft} />,
            ],
            moveRight: <FontAwesomeIcon icon={faChevronRight} />,
            moveAllRight: [
              <FontAwesomeIcon icon={faChevronRight} />,
              <FontAwesomeIcon icon={faChevronRight} />,
            ],
          }}
        />
      </div>

      <div className="product-segments">
        <div className="product-label" style={{ marginTop: "8px" }}>
          {t("SEGMENTS", "SEGMENTS")}
        </div>
        <DualListBox
          options={segmentsOptions}
          selected={segmentsSelected}
          onChange={onChangeSegment}
          icons={{
            moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
            moveAllLeft: [
              <FontAwesomeIcon icon={faChevronLeft} />,
              <FontAwesomeIcon icon={faChevronLeft} />,
            ],
            moveRight: <FontAwesomeIcon icon={faChevronRight} />,
            moveAllRight: [
              <FontAwesomeIcon icon={faChevronRight} />,
              <FontAwesomeIcon icon={faChevronRight} />,
            ],
          }}
        />
      </div>

      <div
        style={{
          width: "50%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "2rem",
          margin: "12px 0",
        }}
      >
        <Field
          name="orderMinimumQuantity"
          as={FormNumberInput}
          label={t("Minimum ordering quantity", "Minimum ordering quantity")}
        />
        <Field
          name="orderMaximumQuantity"
          as={FormNumberInput}
          label={t("Maximum ordering quantity", "Maximum ordering quantity")}
        />
      </div>
    </>
  );
};
