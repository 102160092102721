import {
  Card,
  CardActions,
  CardContent,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import {
  faArrowsAlt,
  faEllipsisH,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useOrderableGridContext } from "../common/OrderableGrid/OrderableGrid";
import { useApi } from "../../api/useApi";
import { Size } from "../../domain/Product";
import { Menu } from "../common/Menu";
import { TextToggledit } from "../common/TextToggledit";

import { selectSize, sizesActions, SizeState } from "./sizes.state";

import "./SizeCard.scss";
import { OrderableGridHandle } from "../common/OrderableGrid/OrderableGridHandle";
import { useTranslation } from "react-i18next";

export const SizeCard: React.FC<{
  id: SizeState["id"];
  order: number;
  onSave: () => void;
}> = (props) => {
  const { order } = props;
  const size = useSelector(selectSize(props.id));
  const dispatch = useDispatch();
  const api = useApi();
  const valuesOrderableGrid = useOrderableGridContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      valuesOrderableGrid.droppedItem !== false &&
      valuesOrderableGrid.droppedItem === props.id &&
      !isSubmitting
    ) {
      updateSize({ ...size });
    }
  });

  if (!size) {
    return null;
  }

  const updateSize = (changes: Partial<Size>) => {
    setIsSubmitting(true);
    api
      .updateSize(size.id)({ ...size, ...changes, order: order } as any)
      .finally(() => {
        props.onSave && props.onSave();
        setIsSubmitting(false);
      });
  };

  const deleteSize = () => {
    Swal.fire({
      title: t(
        "Are you sure you want to delete this size?",
        "Are you sure you want to delete this size?"
      ),
      showCancelButton: true,
      confirmButtonColor: "#272626",
      confirmButtonText: t("Delete", "Delete"),
      cancelButtonText: t("Cancel", "Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        api.deleteSize(size.id).catch((error) => {
          if (error.response && error.response.status === 422) {
            let errors = "";
            const keysErrors = Object.keys(error.response.data);
            if (keysErrors.length > 0) {
              for (let index = 0; index < keysErrors.length; index++) {
                errors += error.response.data[keysErrors[index]] + "\n";
              }
            }
            Swal.fire(
              t("Error!", "Error!"),
              errors ||
                t(
                  "An error ocurred, please review the inserted data.",
                  "An error ocurred, please review the inserted data."
                ),
              "error"
            );
          }

          throw Promise.reject(error);
        });
        dispatch(sizesActions.removed(size.id));
      }
    });
  };

  return (
    <Card className="size-card">
      <CardContent>
        <TextToggledit
          name="size-name"
          text={size.name}
          onChange={(name) => {
            dispatch(sizesActions.nameChanged({ id: size.id, name }));
            return updateSize({ name });
          }}
        />
      </CardContent>
      <CardActions className="size-actions">
        <OrderableGridHandle>
          <FontAwesomeIcon icon={faArrowsAlt} />
        </OrderableGridHandle>
        <Menu
          icon={faEllipsisH}
          render={({ close }) => {
            const onDelete = () => {
              deleteSize();
              close();
            };

            return (
              <MenuItem onClick={onDelete}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faTrash} />
                </ListItemIcon>
                <ListItemText primary={t("Delete", "Delete")} />
              </MenuItem>
            );
          }}
        ></Menu>
      </CardActions>
    </Card>
  );
};
