import React, { useState } from "react";

import { useApi } from "../../api/useApi";
import { AsyncButton } from "../common/AsyncButton";
import { ErrorMessage } from "../common/ErrorMessage";
import { noop } from "../common/helpers";
import { useAsync } from "../useAsync";

import "./AddSize.scss";
import { useTranslation } from "react-i18next";

export const AddNewSize: React.FC<{
  onSave: (args: { id: number; name: string; order: number }) => void;
}> = (props) => {
  const [name, setName] = useState("");
  const { user, createSize, fetchSizes } = useApi();
  const createSizeAsync = useAsync(createSize(user.brandId));
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState<boolean | string>(false);

  const nameIsValid = name.length > 0;

  const onSave = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (nameIsValid) {
      createSizeAsync
        .start({ name, shortName: name, order: 0 })
        .then((res) => {
          setSuccessMessage("Size successfully saved");

          setTimeout(() => {
            props.onSave({ id: res.id, name, order: res.order });
          }, 1000);
        })
        .catch(noop);
    }
  };

  return (
    <form className="add-size-form" onSubmit={onSave}>
      <h3>{t("Add new size", "Add new size")}</h3>

      {createSizeAsync.isFailure ? (
        <ErrorMessage
          message={t("Error while creating size", "Error while creating size")}
        />
      ) : null}

      <input
        type="text"
        className="size-name-input"
        placeholder={t("Name", "Name")}
        required
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      <div className="save-button">
        <AsyncButton isPending={createSizeAsync.isPending}>
          {t("Save", "Save")}
        </AsyncButton>
        {successMessage ? <div>{successMessage}</div> : null}
      </div>
    </form>
  );
};
