import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, MenuItem } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { ProductVariationTexture } from "../../domain/Product";
import { SmartModal } from "../common/SmartModal";
import { selectTextures, texturesActions } from "../Texture/textures.state";
import { AddNewTexture } from "./AddTexture";

import "./TextureSelector.scss";

export const TextureSelector: React.FC<{
  value: ProductVariationTexture["id"] | undefined;
  onChange: (texture: ProductVariationTexture | null) => void;
}> = (props) => {
  const { value, onChange } = props;

  const textures = useSelector(selectTextures);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <div className="texture-selector">
      <Select
        className="product-texture-select"
        value={value}
        label={t("Texture", "Texture")}
        placeholder={t("Select texture", "Select texture")}
        onChange={(event) => {
          const selectedTexture = event.target.value as number;
          const newTexture = textures.find(
            (texture) => texture.id === selectedTexture
          );

          onChange(newTexture || null);
        }}
      >
        <MenuItem value={0}>{t("Select texture", "Select texture")}</MenuItem>
        {textures.map((texture) => (
          <MenuItem key={texture.id} value={texture.id}>
            {texture.name}{" "}
            <img
              src={texture.pictureTexture}
              style={{ width: "16px", height: "16px", marginLeft: "16px" }}
            />
          </MenuItem>
        ))}
      </Select>

      <SmartModal
        body={({ closeModal }) => (
          <AddNewTexture
            onSave={(args) => {
              let newTexture = texturesActions.added(args);
              dispatch(newTexture);
              onChange(newTexture.payload || null);
              closeModal();
            }}
          />
        )}
      >
        <FontAwesomeIcon icon={faPlus} className="icon-button" />
      </SmartModal>
    </div>
  );
};
