import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { IconButton } from "../common/IconButton";
import { SmartModal } from "../common/SmartModal";

import { AddNewSize } from "./AddSize";
import { SizeCard } from "./SizeCard";
import { sizesActions, SizeState } from "./sizes.state";

import "./SizeList.scss";
import { OrderableGrid } from "../common/OrderableGrid/OrderableGrid";
import { OrderableGridItem } from "../common/OrderableGrid/OrderableGridItem";
import { moveItemById } from "../common/OrderableGrid/moveItem";
import { Size } from "../../domain/Product";
import { useTranslation } from "react-i18next";

interface SizeListProps {
  initialSizes: Size[];
}

export const SizeList: React.FC<SizeListProps> = (props) => {
  const { initialSizes } = props;
  const [sizes, setSizes] = useState<SizeState[]>([]);
  const [droppedItem, setDroppedItem] = useState<boolean | number>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onMove = (sourceId: any, destinationId: any) => {
    setSizes(moveItemById({ sourceId, destinationId }));
  };

  const onDrop = (sourceId: number) => {
    setDroppedItem(sourceId);
  };

  useEffect(() => {
    setSizes(initialSizes);
  }, [initialSizes]);

  const onSave = () => {
    setDroppedItem(false);
  };

  return (
    <>
      <div className="size-list">
        <OrderableGrid itemsType="sizes" droppedItem={droppedItem}>
          {sizes.map((size, order) => (
            <OrderableGridItem
              key={size.id}
              id={size.id}
              onMoveItem={onMove}
              onDropItem={onDrop}
            >
              <SizeCard
                key={size.id}
                id={size.id}
                order={order}
                onSave={onSave}
              />
            </OrderableGridItem>
          ))}
        </OrderableGrid>
      </div>

      <SmartModal
        body={({ closeModal }) => (
          <AddNewSize
            onSave={(size) => {
              dispatch(sizesActions.added(size));
              setSizes((sizes) => [...sizes, size]);
              closeModal();
            }}
          />
        )}
      >
        <IconButton icon={faPlus}>
          {t("Add new size", "Add new size")}
        </IconButton>
      </SmartModal>
    </>
  );
};
